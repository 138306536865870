import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Heading} from 'grommet';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<Heading level="3" size="small" responsive={true} alignSelf="center">
    PneumatoSim for OtoSim 2 includes:
</Heading>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Pneumatic Ear with Pressure Hose`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Pneumatic Otoscope`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`PneumatoSim™ Software & Manual on USB Key`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`1-Year Warranty (Extended Warranties for a total of 2 or 3 years are available for purchase)`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Protective Case`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`Note: PneumatoSim™ is not a stand-alone product and requires OtoSim 2™ to function`}</MDXTag></MDXTag>

export const _frontmatter = {};

  