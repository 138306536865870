import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`PneumatoSim™ is an add-on to the OtoSim 2™, and focuses on pneumatic otoscopy. Through hands-on insufflation and otoscopy experience, students can enhance their skills and confidence in using a pneumatic otoscope. Give your students a better learning experience to excel in the clinical environment.`}</MDXTag></MDXTag>

export const _frontmatter = {};

  