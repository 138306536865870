import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`When we first launched the OtoSim™ Otoscopy Training & Simulation System, many of our customers told us that Pneumatic Otoscopy was a critical feature that was missing. At OtoSim Inc, we listened to your feedback and are pleased to present the PneumatoSim™ Pneumatic Otoscopy Upgrade Kit.`}</MDXTag></MDXTag>

export const _frontmatter = {};

  