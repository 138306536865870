import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import { Box, Text } from 'grommet';
import ProductIntro from 'components/productIntro';
import SectionTitle from 'components/title/sectionTitle';
import { RequestBar, RequestBarExtended } from 'components/requestBar';
import { ImageStack, Card } from 'components/pageCards';
import Intro from '../../content/products/pneumatosim/intro.mdx';
import Intro2 from '../../content/products/pneumatosim/intro2.mdx';
import Features from '../../content/products/pneumatosim/features.mdx';
import Includes from '../../content/products/pneumatosim/includes.mdx';

const Pneumatosim = ({ data }) => (
  <Layout>
    <Head pageTitle={data.pneumatosimJson.title} />
    <Box fill="horizontal">
      <SectionTitle title="PneumatoSim™" />
      <Box width="xlarge" alignSelf="center">
        <ProductIntro>
          <Intro />
        </ProductIntro>
        <RequestBar />
        <ImageStack
          image={data.pneumatosimJson.pagePic1}
          textColor="dark-1"
          textAlign="right"
        >
          <Text size="medium">
            <Intro2 />
          </Text>
        </ImageStack>
        <Card image={data.pneumatosimJson.pagePic2} alignImage="right">
          <Includes />
        </Card>
        <Box
          background="light-1"
          pad={{ right: 'large', left: 'large', bottom: 'small' }}
        >
          <Features />
        </Box>
        <RequestBarExtended />
      </Box>
    </Box>
  </Layout>
);

Pneumatosim.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Pneumatosim;

export const query = graphql`
  query PneumatosimQuery {
    pneumatosimJson {
      title
      pagePic1 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pagePic2 {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
