import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Heading} from 'grommet';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<Heading level="3" size="small" responsive={true} alignSelf="center">
    PneumatoSim key features:
</Heading>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`PneumatoSim upgraded software – Easy to install and is built into the OtoSim 2™ software for quick access—no need to switch between different programs`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`PneumatoSim content – Identify and be familiar with the way the tympanic membrane behaves and looks when insufflated under various ear pathologies`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Verifiable scenarios – Pressure applied is measured and displayed on a pressure graph, and the ear drum moves with the amount of pressure applied to build student confidence and technique`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Realistic earform – Pneumatic ear is easily interchangeable with the regular ear on the OtoSim™ base unit and allows students to make a proper seal at the opening of the ear canal with the pneumatic otoscope`}</MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  